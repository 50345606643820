import React, { useEffect, useState, useRef } from "react";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/jumbo-button/dist/style.css";
import "@ingka/pill/dist/style.css";
import "@ingka/search/dist/style.css";

import Select, { Option, OptGroup } from "@ingka/select";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/forms/dist/style.css";
import "@ingka/focus/dist/style.css";
import Switch from "@ingka/switch";

import "@ingka/svg-icon/dist/style.css";
import "@ingka/focus/dist/style.css";
import "@ingka/toggle/dist/style.css";
import ToggleList from "../components/ToggleList";
// import SiteList from "../components/SiteList";
// import UnitList from "../components/UnitList";
import { useMsal } from "@azure/msal-react";
import BrowseDetails from "../components/BrowseDetails";

import { useProjectContext } from "../contexts/ProjectContext";
import { Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Countries } from "../countries";
import SiteForm from "../components/CreateNewProject/Site/SiteForm";
import UnitForm from "../components/CreateNewProject/Units/UnitForm";
import Button from "@ingka/button";
import ScrollMenu from "react-horizontal-scrolling-menu";

import { Link } from "react-scroll";
import Sidebar from "../components/Sidebar";

import chevronDown from "@ingka/ssr-icon/paths/chevron-down";

import Pill from "@ingka/pill";
import ColumnSelecter from "../components/HomePage/ColumnSelecter";
import FilterIndicators from "../components/HomePage/FilterIndicators";

export default function Homepage() {
  const [showModal, setShowModal] = useState(false);
  const {setBatchProjects}=useProjectContext()

  const [projectName, setProjectName] = React.useState("");
  const [projectType, setProjectType] = React.useState("");
  const [country2, setCountry2] = React.useState(0);
  const [city, setCity] = React.useState("");
  const [typeOfEstablishment, setTypeOfEstablishment] = React.useState("");
  const [siteType, setSiteType] = React.useState("");
  const [leasePeriod, setLeasePeriod] = React.useState("");
  const [site_IR_Status_dp4, seTsite_IR_Status_dp4] = React.useState("");
  const [site_IR_Approver, setSite_IR_Approver] = React.useState("");
  const [site_IR_Decision_Date, setSite_IR_Decision_Date] = React.useState("");

  const [mainProject, setMainProject] = React.useState("");
  const [project_ID, setProject_ID] = React.useState("");
  const [m_tool_ID, setM_tool_ID] = React.useState("");
  const [bussiness_Unit_ID, setBussiness_Unit_ID] = React.useState("");
  const [opening_Fy_Tertial, setOpening_Fy_Tertial] = React.useState("");
  const [cbd_Name, setCbd_Name] = React.useState("");
  const [unifier_ID, setUnifier_ID] = React.useState("");
  const [projectStatus, setProjectStatus] = React.useState("");

  const [advancedCreation, setAdvancedCreation] = React.useState(false);
  const [editMultiple, setEditMultiple]=useState(false)

  const handleAdvancedCreation = () => {
    setAdvancedCreation(!advancedCreation);
  };

  function urlhash(link) {
    window.location.hash = link;
  }

  const { addProject, showUnitsInputs } = useProjectContext();

  let navigate = useNavigate();
  // const routeChange = () => {
  //   let path = `/Project-Page`;
  //   navigate(path);
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let country = Countries[country2].name;

    const project = {
      projectName,
      projectType,
      country,
      city,
      typeOfEstablishment,
      siteType,
      leasePeriod,
      site_IR_Status_dp4,
      site_IR_Approver,
      site_IR_Decision_Date,

      mainProject,
      project_ID,
      m_tool_ID,
      bussiness_Unit_ID,
      opening_Fy_Tertial,
      cbd_Name,
      unifier_ID,
      projectStatus,
    };

    let response = await addProject(project);

    navigate("/project/" + response.project_ID);
  };

  const { accounts } = useMsal();
  const name = accounts[0].name.split(" ")[0];

  const pullData = () => {
    setShowModal(false);
  };

  async function handleSwitch() {
    if (editMultiple) {
      setEditMultiple(false)
      setBatchProjects([])
    }
    else {
      setEditMultiple(true)
    }
  }

  return (
    <div className="homepage">
      <div class="btn-group" style={styles.btnGroupDiv}></div>

      {/* 
        <Link
          activeClass="active"
          to="projects"
          spy={true}
          smooth={false}
          offset={-70}
          duration={500}
          style={styles.btnGroup}
        >
        <Button type="emphasised" text="Projects" style={styles.btnGroup} />
        </Link>
        <Button type="emphasised" text="Sites" style={styles.btnGroup} onClick={(e) => navigate('/sites')} />
        <Button type="emphasised" text="Units" style={styles.btnGroup} onClick={(e) => navigate('/units')}/> */}

      {/* <Link
          activeClass="active"
          to="sites"
          spy={true}
          smooth={false}
          offset={-70}
          duration={500}
          style={styles.btnGroup}
        >
        </Link>

        <Link
          activeClass="active"
          to="units"
          spy={true}
          smooth={false}
          offset={-70}
          duration={500}
          style={styles.btnGroup}
        >
        </Link> */}

      {/* </div> */}

      <div classname="createDetails" style={styles.createDetails}>
        <div className="greeting" style={styles.greeting}>
          <div className="create" style={styles.create}>
            <p classname="createText" style={styles.createText}>
              <Button
                type="emphasised"
                text="Create project"
                style={styles.createProjectBtn}
                onClick={() => setShowModal(true)}
              />
            </p>
          </div>

          <h2 className="greetingText" style={styles.greetingText}>
            Welcome {name}
          </h2>
        </div>
        {/* <div className="row" style={styles.row}>
          <div className="column" style={styles.column}>
            <button
              type="button"
              onClick={() => setShowModal(true)}
              style={styles.jumboBtn}
              className="jumbo-btn jumbo-btn--emphasised jumbo-btn--inverse"
            >
              <span className="jumbo-btn__inner">
                <svg
                  style={styles.plusButn}
                  focusable="false"
                  viewBox="0 0 24 24"
                  className="svg-icon pill__icon"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13 11H18V13H13V18H11V13H6V11H11V6H13V11Z"
                  ></path>
                </svg>
                <span style={styles.project} className="jumbo-btn__label">
                  Project
                </span>{" "}
              </span>{" "}
            </button>
          </div>

       

        </div> */}
      </div>

      {/* <div className="browse" style={styles.browse}>
        
    <p classname="createText" style={styles.createText}>
       
      
            <div className="flex flex-col items-center justify-center ">
                  <Sidebar />
                </div>
      
        </p>
      </div> */}

      {/* <div classname="browseDetails" style={styles.bDetails}> 

        <BrowseDetails />
      </div> */}

      <div
        className="tabsWrapper"
        onmouseover="urlhash('#projects')"
        name="projects"
        style={styles.tabsWrapper}
        id="projects"
      >
        <div>
          <h3 style={styles.projectsText}>Projects</h3>

          <div classname="browseDetails" style={styles.bDetails}>
            <div style={styles.switch} className="switchWrapper">
              <Switch label="Edit multiple" className="switchBatch" checked={editMultiple} onChange={e=> handleSwitch()}/>
            </div>

            <div className="browseDetails-item" style={styles.bDetailsItem}>
              <ColumnSelecter />
            </div>
            <div className="browseDetails-item" style={styles.bDetailsItem}>
              <Sidebar />
            </div>
            <div style={styles.bDetailsItem}>
              <BrowseDetails />
            </div>
          </div>
        </div>

        <div style={styles.projectList}>
          <FilterIndicators />

          <ToggleList
            editBatch={editMultiple}
            switchValue={(e) => handleSwitch()}
          />
        </div>
      </div>
      {/* 
      <div className="sitesWrapper" style={styles.tabsWrapper} id="sites">
        <SiteList />
      </div>
      
      <div className="unitWrapper" style={styles.tabsWrapper} id="units">
      <UnitList />
      </div> */}

      <SiteForm open={showModal} emitData={pullData} />
    </div>
  );
}

const styles = {
  greeting: {
    backgroundColor: "white",
    minHeight: "5vh",
    fontSize: "28px",
    marginTop: "20vh",
    position: "relative",
  },

  greetingText: {
    position: "absilute",
    bottom: "0px",
  },

  create: {
    backgroundColor: "white",
    fontSize: "18px",
    minHeight: "5vh",
    textAlign: "right",
    width: "95%",
  },

  createText: {
    paddingLeft: "5vw",
  },

  createDetails: {
    minHeight: "15vh",
    backgroundColor: "white",
  },

  jumboBtn: {
    height: "1vw",
    width: "70%",
    color: "black",
    backgroundColor: "white",
    border: "1px solid black",
    borderColor: "gray",
  },

  plusButn: {
    border: "2px solid black",
    borderColor: "gray",
    borderRadius: "50%",
  },

  project: {
    paddingRight: "12vw",
  },

  column: {
    float: "left",
  },

  columnTwo: {
    float: "left",
    marginLeft: "5vw",
  },

  columnThree: {},

  row: {
    display: "grid",
    clear: "both",
    marginLeft: "auto",
    marginRight: "auto",
    gridAutoFlow: "column",
    gridColumnGap: "10px",
    alignItems: "center",
  },

  browse: {
    backgroundColor: "white",
    fontSize: "18px",
    minHeight: "5vh",
    display: "flex",
  },

  browseDetails: {
    backgroundColor: "white",
    minHeight: "12vh",
  },

  search: {
    height: "3vw",
    width: "100%",
    margin: "0 auto",
  },

  selectOne: {
    height: "3vw",
    width: "40%",
  },

  selectTwo: {
    height: "3vw",
    width: "40%",
  },

  checkboxOne: {
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "20vw",
    bottom: "2vw",
  },

  projectType: {
    color: "gray",
  },

  hr: {
    border: "0",
    clear: "both",
    display: "block",
    width: "90%",
    backgroundColor: "black",
    height: "1px",
    marginLeft: "auto",
    marginRight: "auto",
  },

  toggle: {
    minHeight: "5vh",
  },

  toggleContainer: {
    marginLeft: "5vw",
  },

  modal: {
    marginLeft: "25vw",
  },

  projectLabelForm: {
    float: "left",
  },

  projectTypeForm: {
    width: "70%",
  },

  projectTypeForm2: {
    width: "90%",
  },

  formColumn: {
    float: "left",
    width: "50%",
  },

  formRow: {
    display: "table",
    clear: "both",
  },

  formBtn: {
    gap: "10px",
  },

  formCheckbox: {
    right: "270px",
  },

  formGap: {
    height: "10vh",
  },

  tabsWrapper: {
    padding: "5vh 5vw 2vh 5vw",
  },

  continueBtn: {
    color: "white",
  },

  backBtn: {
    marginRight: "500px",
  },

  modal3: {},

  modal3Gap: {
    paddingTop: "65vh",
  },

  row2: {
    display: "table",
    clear: "both",
  },

  column2: {
    float: "left",
    width: "33.33%",
    padding: "10px",
  },

  btnGroupDiv: {
    width: "40%",

    tableLayout: "fixed",
    borderCollapse: "collapse",
  },

  btnGroup: {
    color: "white",
    width: "100%",
  },

  projectList: {
    overflowX: "auto",
    width: "102%",
    height: "100%",
  },

  createProjectBtn: {
    color: "white",
    width: "20%",
  },

  projectsText: {
    textAlign: "left",
  },

  bDetails: {
    float: "right",

    height: "8vh",

    display: "grid",
    gridTemplateColumns: "auto auto auto auto",
  },

  bDetailsItem: {
    padding: "10px",
  },
  switch: {
    alignSelf: "center",
    paddingRight: "3vw",
    fontWeight:"bold"
  }
};
