import React, {useEffect} from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import './App.scss';
 // import Button from '@ingka/button'
import Navbar from './components/navbar';
import Footer from "./components/footer"
import Homepage from "./pages/homepage";
import DetailedPage from "./pages/projectPage";
import { SignInButton } from './components/SignInButton'
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal
} from "@azure/msal-react";
import SiteDetailsPage from "./pages/SiteDetailsPage";
import { msalInstance } from './authConfig'
import UserProvider from "./contexts/UserContext";
import ProjectProvider from "./contexts/ProjectContext";
import UnitDetailsPage from "./pages/UnitDetailsPage";


import UnitProvider from "./contexts/UnitContext";
import SitePage from "./pages/SitePage";
import UnitPage from "./pages/UnitPage";
import { ScrollToTop } from "./components/ScrollToTop";
import SiteProvider from "./contexts/SiteContext";
import PostProjectProvider from "./contexts/PostProjectContext";
import ColumnProvider from "./contexts/ColumnContext";
import DecisionPointsProvider from "./contexts/DecisionPointsContext"

import { Link, Element } from "react-scroll";


export default function App() {

  
   //const testEnv = process.env.REACT_APP_TEST
  return (
    <Router>
      <UserProvider>
        <UnitProvider>
          <SiteProvider>
            <ColumnProvider>
              <DecisionPointsProvider>
                <ProjectProvider>
                  <PostProjectProvider>
                    <div className="App">
                      <main>
                        {" "}
                        <AuthenticatedTemplate>
                          <header>
                            <Navbar />
                          </header>

                          <Routes>
                            <Route exact path="/" element={<Homepage />} />
                            <Route
                              exact
                              path="/project/:projectId"
                              element={<DetailedPage />}
                            />

                            <Route exact path="/sites" element={<SitePage />} />
                            <Route exact path="/units" element={<UnitPage />} />
                            <Route
                              exact
                              path="/sites/:siteId"
                              element={<SiteDetailsPage />}
                            />
                            <Route
                              exact
                              path="/units/:unitId"
                              element={<UnitDetailsPage />}
                            />
                          </Routes>
                        </AuthenticatedTemplate>
                        <UnauthenticatedTemplate>
                          <SignInButton />
                        </UnauthenticatedTemplate>
                      </main>

                      <footer>
                        <Footer />
                      </footer>
                    </div>
                  </PostProjectProvider>
                </ProjectProvider>
              </DecisionPointsProvider>
            </ColumnProvider>
          </SiteProvider>
        </UnitProvider>
      </UserProvider>
      <ScrollToTop />
    </Router>
  );
}


